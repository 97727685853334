.breadrcumbs {
	
	&__list {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin:0;
	  padding: 0 0 40px;
	  text-indent: 0;
	  list-style-type: none;
	}

	&__section {
		position: relative;
		display: inline-block;
		margin:0;
	  padding: 0;
	  text-indent: 0;
	  list-style-type: none;
	  margin-bottom: 8px;
	  & + #{&} {
	  	padding-left: 40px;
	  	&::before {
	  		content: '';
	  		position: absolute;
	  		display: block;
	  		width: 8px;
				height: 7px;
				top:12px;
				left: 16px;
				background-image: url('/img/br-del.svg');
				background-repeat: no-repeat;
	  	}
	  }
	}

	&__item {
		display: inline-block;
	}

	&__text {
		@include BeauSansLight;
		@include font($mid-grey, 12px, 28px);
		text-transform: uppercase;
		letter-spacing: 0.5px;
		white-space: nowrap;
		display: inline-block;
	}
}

a.breadrcumbs__item:hover {
	.breadrcumbs__text {
		color: $brand-color;
	}
}