.progress-bar	{
	
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__bar-col {

		&--current {
			text-align: left;
		}

		&--target {
			text-align: right;
		}
	}

	&__label {
		@include BeauSansLight;
		@include font($mid-grey, 12px, 18px);
		letter-spacing: 1.5px;
		text-transform: uppercase;
		display: inline-block;
		margin-bottom: 3px;
	}

	&__price {
		@include BeauSansBlack;
		@include font($text-color, 24px, 28px);
		letter-spacing: -1px;
	}

	&__container {
		margin-top: 14px;
		height: 20px;
		width: 100%;
		background-color: #fff;
		border: 4px solid #e0e4eb;
		border-radius: 10px;
		overflow: hidden;
	}

	&__inner-fill {
		height: 100%;
		border-radius: 6px;
		background-image: linear-gradient(180deg, #ffc039 0%, #cb6d00 100%);
		transition: width 0.4s linear; 
	}
}