.is-form {
	
	p {
		margin: 10px 0;
	}

	&__field {
		margin: 16px 0 0;
		&.has-icon {
			position: relative;
			.input-icon {
				position: absolute;
				z-index: 2;
				display: inline-block;
				background: transparent;
				width: 40px;
				height: 40px;
				background-image: linear-gradient(180deg, #ffc039 0%, #cb6d00 100%);
				border-radius: 20px;
				right: 8px;
				bottom: 8px;
				svg {
					@include centerIt(absolute);
					display: block;
					use {
						stroke: transparent;
						fill: transparent;
					}
				}
				&:active {
					transform: scale(0.9);
				}
			}
			.input-icon--hidden {
				visibility: hidden;
				z-index: -1;
				pointer-events: none;
			}
			.is-input {
				padding-right: 60px;
			}
		}
		&--wide {
			.is-input--theme {
				width: 100%;
				max-width: none;
			}
		}

		&--file {
			position: relative;
			.input-placeholder {
				position: absolute;
				left: 24px;
				bottom: 18px;
				color: #7f7f7f;
				z-index: 1;
				@include text-whith-ellipsis(14px, 14px, 1);
				max-width: 220px;
			}
			&.is-attached {
				.input-icon {
					visibility: hidden;
					z-index: -1;
					pointer-events: none;
				}
				.input-icon--hidden {
					pointer-events: auto;
					visibility: visible;
					z-index: 2;
				}
			}
		}
	}

	&__label {
		display: inline-block;
		@include font($text-color, 16px, 16px);
		@include BeauSansLight;
		margin: 0 0 12px;
		padding-left: 24px;
	}

	&__group {
		display: flex;
	  flex-direction: row;
	  flex-wrap: nowrap;
	  align-items: stretch;
	  justify-content: space-between;
	  .is-gfield {
	  	flex-grow: 1;
    	width: 100%;
    	margin-top: 0;
	  }
	  .is-gfield + .is-gfield {
			margin-left: 24px;
	  }
	  & + #{&} {
	  	margin-top: 16px;
	  }
	  @media (max-width: map-get($breakpoints, medium)) {
	  	flex-wrap: wrap;
	  	.is-gfield + .is-gfield {
	  		margin-left: 0;
	  		margin-top: 16px;
	  	}
	  }
	}

	&__title {
		@include font($title-color, 32px, 45px);
		font-weight: normal;
		margin: 0 0 11px;
	}

	&__submit {
		margin: 40px 0 0;
	}
}

// mods 

.is-form__field--select {
	position: relative;
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		right: 16px;
		top: 46px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 5px 0 5px;
		border-color: $brand-color transparent transparent transparent;
	}
}

.is-form__field .ff-error-parent {
	padding-top: 8px;
	@include font($error, 13px, 16px);
}

.is-form__field.state-error {
	.is-form__lable {
		color: $error;
	}
	&.is-form__field--file .input-placeholder {
		bottom: 42px;
	}
	&.is-form__field.has-icon .input-icon {
		bottom: 32px;
	}
}
.is-form__group.state-error {
	.field-checkbox i {
		border-color: $error;
	}
	.checkbox-label {
		color: $error;
		a {
			color: $error;
		}
	}
}

.is-form__field.state-error .ff-error-parent {
	display: block;
}

.is-form-field .ff-error-parent {
	display: none;
}