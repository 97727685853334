.page-partners {
	
	&__main {
		margin-top:72px;
		margin-bottom: 64px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 40px;
			margin-bottom: 80px;
		}
	}
}

// mods 

.page-partners {
	.page__hero .page__head-title {
		color: $brand-color;
	}
	.cols-layout__col-8 {
		.is-style {
			padding: 0 24px;
			margin-bottom: 48px;
		}
		.responsive-img {
			width: 100%;
		}
	}
	.cols-layout__col-4 {
		margin-left: 56px;
		.page__title {
			letter-spacing: -2px;
			white-space: nowrap;
		}
		.aside-banner {
			margin-top: 48px;
		}
	}
	@media (max-width: map-get($breakpoints, medium)) {
		.cols-layout__col-8 .is-style {
			padding: 0;
		}
		.cols-layout__col-4 {
			margin-left: 0;
		}
	}
}
