.m-nav {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	background-color: #fff;
	transform: translateX(110%);
	transition: transform 0.3s ease;
	&.is-active {
		transform: translateX(0);
	}

	&__inner {
		padding: 22px 0 0 36px;
	}

	&__scrollable {
		height: calc(100vh - 22px);
		overflow-y: auto;
	}

	&__close {
		position: absolute;
		top: 16px;
		right: 17px;
		padding: 4px;
		-webkit-tap-highlight-color: rgba($title-color, 0.15);
		svg {
			display: block;
		}
	}

	&__sect {
		border-bottom: 1px solid #e0e4eb;
		margin-right: 36px;
	}

	&__text {
		@include BeauSansLight;
		font-size: 24px;
		line-height: 31px;
		color: $text-color;
		letter-spacing: -0.45px;
		-webkit-tap-highlight-color: rgba($title-color, 0.15);

		&--sm {
			font-size: 18px;
			line-height: 23px;
			letter-spacing: -0.34px;
		}

		&.active {
			color: $brand-color;
		}
	}

	&__item {
		display: block;
		& + & {
			margin-top: 33px;
		}
	}

	&__main {
		margin-top: 50px;
		margin-bottom: 33px;
	}

	&__second {
		margin-top: 40px;
		margin-bottom: 35px;
		.m-nav__item + .m-nav__item {
			margin-top: 35px;
		}
	}

	&__footer {
		margin-top: 40px;
		padding-right: 36px;
		padding-bottom: 80px;
		text-align: center;
		.m-phone {
			@include BeauSansBlack;
			@include font($text-color, 24px, 31px);
			letter-spacing: -1.71px;
			white-space: nowrap;
			margin-bottom: 9px;
		}
		.footer-sm-text	{
			color: #b7c8d0;
			font-size: 14px;
		}
	}
}