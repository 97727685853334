.help-banner {
	padding: 40px 30px;
	background-color: #fff;
	min-height: 334px;

	&__inner {
		padding-left: 8px;
		@media (max-width: map-get($breakpoints, medium)) {
			text-align: center;
			padding-left: 0;
		}
	}
	
	&__title {
		@include BeauSansBlack;
		@include font($brand-color, 24px, 30px);
		margin: 0 0 20px;
	}

	&__price {
		@include BeauSansBlack;
		@include font($text-color, 24px, 30px);
		letter-spacing: -1px;
	}

	&__text {
		@include BeauSansLight;
		@include font($mid-grey, 14px, 24px);
		margin: 0; 
	}

	&__link {
		margin-top: 36px;
		width: 100%;
		.button__text {
			padding: 0 12px;
		}
	}
}