.is-select {
    display: block;
    font-size: 14px;
    @include BeauSansLight;
    color: #7f7f7f;
    line-height: 30px;
    padding: 12px 62px 12px 24px;
    width: 100%;
    max-width: 100%; 
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #e0e4eb;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: 28px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDMyIDMyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1MC4yICg1NTA0NykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXAgMjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJHcm91cC0yIj4KICAgICAgICAgICAgPHBhdGggZD0iTTE2LDMyIEMyNC44MzcsMzIgMzIsMjQuODM3IDMyLDE2IEMzMiw3LjE2MyAyNC44MzcsMCAxNiwwIEM3LjE2MywwIDAsNy4xNjMgMCwxNiBDMCwyNC44MzcgNy4xNjMsMzIgMTYsMzIgWiIgaWQ9IlNoYXBlIiBmaWxsPSIjRTBFNEVCIiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD4KICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJTaGFwZSIgc3Ryb2tlPSIjNEQ1NzVDIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTYuMDAwMDAwLCAxNi41MDAwMDApIHJvdGF0ZSg5MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTYuMDAwMDAwLCAtMTYuNTAwMDAwKSAiIHBvaW50cz0iMTMuNSAyMS41IDE4LjUgMTYuNSAxMy41IDExLjUiPjwvcG9seWxpbmU+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: right 12px top 50%, 0 0;
    background-size: 32px auto, 100%;
}
.is-select::-ms-expand {
    display: none;
}
.is-select:hover {
    border-color: #e0e4eb;
}
.is-select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222; 
    outline: none;
}
.is-select option {
    font-weight:normal;
}