.pager {
	display: flex;
	align-items: center;
	justify-content: center;
	
	&__prev, &__next {
		background-image: linear-gradient(180deg, #f52239 0%, #af1727 100%);
		border-radius: 16px;
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			top:0;
			left:0;
			right: 0;
			bottom: 0;
			background-image: url('/img/arr.png');
			background-repeat: no-repeat;
			background-size: 5px 10px;
		}
	}

	&__link {
		display: inline-block;
		min-width: 32px;
		height: 32px;
		padding: 0 5px;
		line-height: 32px;
		text-align: center;
		@include BeauSansSemi;
		font-size: 16px;
		color: #4d575c;
		&:hover {
			color: rgba(#4d575c, 0.6);
		}
		&.active {
			color: $brand-color;
		}
		
		&.disabled {
			pointer-events: none;
			background-color: #b7c8d0;
			background-image: none;
		}
	}

	&__prev {
		margin-right: 24px;
		&::before {
			background-position: left 12px center;
		}
		@media (max-width: map-get($breakpoints, small)) {
			margin-right: 4px;
		}
	}

	&__next {
		margin-left: 24px;
		&::before {
			transform: scale(-1);
			background-position: left 12px center;
		}
		@media (max-width: map-get($breakpoints, small)) {
			margin-left: 4px;
		}
	}

}