// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

%white-state {
	color:#fff;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

%brand-state {
	color: $brand-color;
}

%disabled-state {
	color:$light-grey;
}

.button {
	position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  border: 0;
  outline: 0;
  background: 0 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  touch-action: manipulation;
  overflow: visible;
  font-family: inherit;
  text-align: center;
  cursor:pointer;
  transition: background-color .25s ease-out;
  @include on-event {
  	text-decoration: none;
  }

	&__text {
		@include BeauSansSemi;
		font-size: 16px;
		padding: 0 40px;
		display: inline-block;
		position: relative;
		transition: color .25s ease-out;
	}
}

// mods 

.button--md {
	border-radius: 28px;
	.button__text {
		height: 48px;
		line-height: 50px;
	}
}

.button--primary {
	background-color: $brand-color;
	background-image: linear-gradient(180deg, #f52239 0%, #af1727 100%);
	@include on-event {
		
	}
	.button__text {
		@extend %white-state;
	}
}

.button--gray {
	background-color: transparent;
	background-image: linear-gradient(to top, #b7c8d0 0%, #e0e4eb 100%);
	.button__text {
		color: #4d575c;
	}
}

.button--disabled {
	cursor: default;
	pointer-events: none;
	background-color: #d8dee3;

	.button__text {
		@extend %disabled-state;
	}
}

// .button.button-state-progress {
// 	background-color: $brand-color;
// 	position: relative;
// 	pointer-events: none;
// 	&::before {
// 		content: '';
// 		position: absolute;
// 		z-index: 1;
// 		top:0;
// 		left:0;
// 		right:0;
// 		bottom:0;
// 		background: image('spinner.svg') no-repeat 50% 50%;
// 		box-shadow: inset 0 0 0 1000px rgba($brand-color,0.2);
// 	}
// 	.button__text {
// 		opacity: 0.1;
// 		@extend %white-state;
// 	}
// }
