.is-textarea {
	touch-action: manipulation;
	resize: none;
	position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 160px;
  padding: 12px 24px;
  outline: none;
  text-align: left;
  @include font(#7f7f7f, 16px, 24px);
  border: 1px solid #e0e4eb;
  border-radius: 16px;
  -webkit-tap-highlight-color: transparent;
}