.b-page-banner {
	background-color: #f6f7f9;
	position: relative;
	.wrapper {
		position: static;
		@media (max-width: map-get($breakpoints, medium)) {
			padding: 0;
		}
	}

	&__inner {
		display: flex;
		@media (max-width: map-get($breakpoints, medium)) {
			flex-flow: column nowrap;
			align-items: center;
		}
	}

	&__content {
		box-sizing: border-box;
		flex-basis: 50%;
		max-width: 100%;
		width: 50%;
		@media (max-width: map-get($breakpoints, medium)) {
			width: 100%;
			flex-basis: 0;
		}
	}

	&__content-inner {
		padding: 80px 0 80px 104px;
		@media (max-width: map-get($breakpoints, medium)) {
			padding: 56px 12px 5px;
			text-align: center;
		}
	}

	&__title {
		margin: 0 0 22px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin: 0 0 16px;
		}
	}

	&__text {
		@media (max-width: map-get($breakpoints, medium)) {
			font-size: 16px;
			line-height: 32px;
		}
	}

	&__button {
		margin: 40px 0 0;
		@media (max-width: map-get($breakpoints, medium)) {
			margin: 32px 0 0;
		}
	}

	&__image-col {
		box-sizing: border-box;
		-webkit-box-flex: 1;
		flex-basis: 0;
		max-width: 100%;
		position: absolute;
		top:-62px;
		right: 0;
		bottom: 0;
		width: 50%;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: contain;
		@media (max-width: map-get($breakpoints, medium)) {
	    position: relative;
	    top: 0;
	    width: 100%;
	    height: 298px;
	    flex-basis: auto;
	    background-size: cover;
	    background-position: center top;
		}
	}
}