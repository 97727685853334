.wrapper {
	position: relative;
  width: 98%;
  max-width: 1244px;
  padding: 0 10px;
  margin: 0 auto;

  &--sm {
  	max-width: 1036px;
  }

  &--md {
    max-width: 1084px;
  }

  &--fw {
  	width: calc(100% - 40px);
    @media (max-width: map-get($breakpoints, medium)) {
      width: calc(100% - 24px);
    }
  }

  @media (max-width: map-get($breakpoints, medium)) {
    width: 100%;
    padding: 0 12px;
  }
}