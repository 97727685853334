.page-hki {
	
	&__main {
		margin-top: -225px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: -260px;
			.cols-layout {
				flex-direction: column-reverse;
				.cols-layout__col-4 {
					margin-top: 0;
					margin-bottom: 56px;
				}
			}
			.b-want-help {
				margin-top: 24px;
			}
		}
	}

	.page-social-group {
		margin-top: 96px;
		margin-bottom: 88px;
	}
}