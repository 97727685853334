.page-clinic {

	&__main {
		margin-bottom: 80px;
	}
	
	&__head-block {
		@include clearfix;
		margin-top: 72px;
		margin-bottom: 40px;

		.button {
			float: right;
			margin-top: 34px;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 32px;
			margin-bottom: 32px;
			text-align: center;
			.button {
				float: none;
				margin-top: 16px;
			}
		}
	}

	&__introtext {
		@include BeauSansLight;
		@include font($text-color, 16px, 32px);
		max-width: 755px;
		margin-top: 0;
		float: left;
		@media (max-width: map-get($breakpoints, medium)) {
			float: none;
			text-align: left;
		}
	}

	&__body {
		.cl-item + .cl-item {
			margin-top: 24px;
		}
	}
}

// mods

.page-clinic {
	.page__hero .page__head-title {
		color: $brand-color;
	}
}