.social-carousel {
	display: block;
	position: relative;
	padding-top: 18px;

	&__title {
		@include BeauSansBlack;
		font-size: 24px;
		line-height: 32px;
		letter-spacing: -0.5px;
		text-align: center;
		margin-bottom: 52px;
	}

	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 55px;
		cursor: pointer;
		position: relative;
		z-index: 2;
		
		use.icon {
			opacity: 0.4;
			transition: fill 0.3s ease-in-out, opacity 0.3s ease-in-out;
		}
		use.icon--fb {
			fill: #3b5998;
		}
		use.icon--vk {
			fill: #4d76a1;
		}
		use.icon--tw {
			fill: #55acee;
		}
		use.icon--odn {
			fill: #f8941e;
		}
	}
	.swiper-slide-thumb-active {
		.social-carousel__btn use.icon {
			opacity: 1;
		}
	}
	.swiper-slide + .swiper-slide {
		box-shadow: -1px 0px 0px 0px rgba(59, 89, 152, 0.15);
	}

	&__icons-group {
		.swiper-container {
			padding-bottom: 22px;
    	margin-bottom: -22px;
		}
	}

	&__widgets {
		border-top: 1px solid #e0e4eb;
		min-height: 389px;
	}
}