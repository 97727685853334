// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'PF BeauSans Pro';
    src: local('PFBeauSansPro-Light'),
        url('../fonts/BeauSans/PFBeauSansPro-Light.woff2') format('woff2'),
        url('../fonts/BeauSans/PFBeauSansPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: local('PFBeauSansPro-Black'),
        url('../fonts/BeauSans/PFBeauSansPro-Black.woff2') format('woff2'),
        url('../fonts/BeauSans/PFBeauSansPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: local('PFBeauSansPro-Regular'),
        url('../fonts/BeauSans/PFBeauSansPro-Regular.woff2') format('woff2'),
        url('../fonts/BeauSans/PFBeauSansPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: local('PFBeauSansPro-SemiBold'),
        url('../fonts/BeauSans/PFBeauSansPro-SemiBold.woff2') format('woff2'),
        url('../fonts/BeauSans/PFBeauSansPro-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@mixin BeauSansBlack {
  font-family: 'PF BeauSans Pro', sans-serif;
  font-weight: 900;
}

@mixin BeauSansSemi {
  font-family: 'PF BeauSans Pro', sans-serif;
  font-weight: 600;
}

@mixin BeauSansReg {
  font-family: 'PF BeauSans Pro', sans-serif;
  font-weight: normal;
}

@mixin BeauSansLight {
  font-family: 'PF BeauSans Pro', sans-serif;
  font-weight: 300;
}
