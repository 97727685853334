.page-404 {
	.page__hero {
		.page__head-title {
			color: #fff;
		}
		.is-text {
			color: #fff;
			margin: 40px 0;
		}
	}	
}