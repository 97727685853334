.doc-item {
	display: inline-block;
	padding-top: 16px;

	&__img {
		margin-bottom: 24px;
	}

	&__name {
		@include BeauSansLight;
		@include font($text-color, 16px, 24px);
		margin: 16px 0;
	}

	&__text {
		@include BeauSansLight;
		line-height: 18px;
		color: #4d575c;
	}
}