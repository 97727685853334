/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 100% / 1.2 $text-font-stack;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-style: normal;
  font-weight: normal;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: lining-nums;
	-moz-font-feature-settings: 'lnum';
	-webkit-font-feature-settings: 'lnum';
	font-feature-settings: 'lnum' on;
}

.is-style {
  p {
    margin: 0;
    @include BeauSansLight;
    @include font($text-color, 16px, 32px);
  }
  p + p {
    margin-top: 32px;
  }

  .page__title {
    margin: 24px 0 40px; 
  }

  h3 {
    @include BeauSansBlack;
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 24px 0;
  }

  br {
    display: block;
    margin: 12px 0;
    content: " ";
  }
  a.is-link {
    color: $title-color;
    &:hover {
      color: $brand-color;
    }
  }
}