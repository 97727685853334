// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.is-footer {
	margin-top: -162px;
	background-color: #f6f7f9;
	padding: 32px 0 40px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		display: block;
		left:0;
		right: 0;
		top: -45px;
		height: 192px;
		background-image: url('/img/footer-decor.png');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
	}
	@media (max-width: map-get($breakpoints, medium)) {
		margin-top: -20px;
	}
	&__inner {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-flow: row nowrap;
		@media (max-width: map-get($breakpoints, medium)) {
			flex-flow: column nowrap;
			align-items: center;
			text-align: center;
		}
	}

	&__main {
		@media (max-width: map-get($breakpoints, medium)) {
			width: 100%;
			border-bottom: 1px solid #e0e4eb;
		}
	}

	&__info-text {
		max-width: 650px;
		padding-top: 12px;
		border-top: 1px solid #e0e4eb;
		@media (max-width: map-get($breakpoints, medium)) {
			border-top: none;
			padding-top: 0;
		}
	}

	&__nav {
		padding-bottom: 32px;
		@media (max-width: map-get($breakpoints, medium)) {
			padding-bottom: 40px;
		}
	}

	&__menu {
		display: flex;
		flex-flow: row nowrap;
		padding: 0;
		margin: 0;
		padding-right: 27px;
    box-sizing: border-box;
    @media (max-width: map-get($breakpoints, medium)) {
    	flex-flow: column nowrap;
    	padding-right: 0;
    }
	}

	&__menu-item {
		display: inline-block;
		&:first-child {
			.is-footer__nav-text {
				padding-left: 0;
			}
		}
		@media (max-width: map-get($breakpoints, medium)) {
			& + & {
				margin-top: 7px;
			}
		}
	}

	&__nav-text {
		@include BeauSansReg;
		@include font($text-color, 16px, 20px);
		letter-spacing: -0.3px;
		white-space: nowrap;
		padding: 1px 16px 3px;
		@include on-event {
			color:$brand-color;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			display: inline-block;
			padding: 10px !important;
		}
	}

	&__right {
		box-sizing: border-box;
		margin-left: auto;
		padding-right: 10px;

		.f-phone {
			display: block;
			@include BeauSansBlack;
			@include font($text-color, 18px, 23px);
			white-space: nowrap;
			@include on-event {
				color:$brand-color;
			}
		}
		@media (max-width: map-get($breakpoints, medium)) {
			margin: 47px auto 25px;
			padding-right: 0;
		}
	}

	&__social {
		margin-top: 32px;
	}

}