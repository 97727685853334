.page-about {
	
	&__intro {
		padding-top: 72px;
		padding-bottom: 80px;

		.cols-layout__col-4 {
			width: 208px;
			flex-basis: 208px;
			margin-left: 80px;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			padding-top: 40px;
			padding-bottom: 56px;
			.cols-layout__col-4 {
				width: auto;
				flex-basis: auto;
				margin-left: 0
			}
		}
	}

	&__mission {
		padding-bottom: 33px;
		.is-columns {
			position: relative;
			top: 33px;
			@media (max-width: map-get($breakpoints, medium)) {
				flex-flow: column nowrap;
				align-items: center;
				.col + .col {
					margin-left: 0;
					margin-top: 24px;
				}
				.col {
					max-width: 420px;
				}
			}
		}
	}

	&__mission-inner {
		background-color: #f6f7f9;
		padding-top: 40px;
	}

	&__main {
		margin-top: 72px;
		margin-bottom: 88px;
		.cols-layout__col-8 {
			padding-left: 104px;
		}
		.cols-layout__col-4 {
			margin-left: 80px;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 40px;
			margin-bottom: 56px;
			.cols-layout__col-8 {
				padding-left: 0;
			}
			.cols-layout__col-4 {
				margin-left: 0;
			}
		}
	}

	&__details {
		margin-top: 72px;
		margin-bottom: 120px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 40px;
			margin-bottom: 80px;
		}
		.b-container {
			display: flex;
			margin-top: 38px;
			.col {
				position: relative;
				padding: 48px 40px 134px;	
				background-color: #fff;
				@include BeauSansLight;
				font-size: 18px;
				p {
					margin: 0;
				}
				b {
					@include BeauSansBlack;
				}
				small {
					line-height: 18px;
					font-size: 14px;
					color: #4d575c;
				}
				table {
					width: 100%;
					border-collapse: collapse;
				}
				td {
					vertical-align: top;
					font-size: 16px;
				}
				.button-wrap {
					margin-top: 32px;
					position: absolute;
					bottom: 48px;
					left: 40px;
				}
			}

			.col1 {
				width: 600px;
			}
			.col2 {
				width: 392px;
				margin-left: auto;
			}
			@media (max-width: map-get($breakpoints, medium)) {
				flex-flow: column nowrap;
				.col1, .col2 {
					width: 100%;
					margin-left: 0;
				}
				.col {
					padding: 32px 24px 40px;
					.button-wrap {
						position: static;
						text-align: center;
					}
				}
				.col2 {
					margin-top: 30px;
					.responsive-img {
						width: 100%;
					}
				}
			}
		}
	}

	&__docs {
		margin-top: 72px;
		margin-bottom: 88px;
		.col + .col {
			margin-left: 24px;
		}

		.col.col-start {
			width: 288px;
			min-width: 288px;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 40px;
			margin-bottom: 80px;
			.is-columns {
				flex-flow: column nowrap;
			}
			.col + .col {
				margin-left: 0;
			}
			.col.col-start {
				width: auto;
				min-width: auto;
			}
		}
	}
}