.cols-layout {
	display: flex;
	
	&__col-8 {
		width: auto;
		flex-grow: 1;
		flex-shrink: 1;
    flex-basis: 0%;
		min-height: 1px;
	}
	&__col-4 {
		width: 288px;
		flex-basis: 288px;
		margin-left: 24px;
		min-height: 1px;
	}

	@media (max-width: map-get($breakpoints, medium)) {
		flex-flow: column nowrap;

		.cols-layout__col-4 {
			margin-top: 32px;
			width: auto;
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 0%;
			margin-left: 0;
		}
	}
}