.page-content {
	
	&__main {
		margin-top: -70px;
		margin-bottom: 80px;
		.is-style {
			a {
				word-break: break-all;
			}
		}
	}
}