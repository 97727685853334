.is-link {
	transition: color 450ms cubic-bezier(.45,1,.32,1);
	cursor: pointer;
	@include on-event {
		text-decoration: none;
	}
	&--has-arrow {
		@include BeauSansSemi;
		@include font($brand-color, 16px, 20px);
		padding: 9px 44px 3px 0;
		display: inline-block;
		position: relative;
		&::after {
			content: '';
			display: block;
			position: absolute;
			top:0;
			right: 0;
			width: 32px;
			height: 32px;
			border-radius: 16px;
			background-image: url('/img/arrow.svg'), linear-gradient(#f52239 0%, #af1727 100%);
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}