.page-contacts {
	
	&__main {
		margin-top: -30px;
	}

	&__items {
		margin-bottom: 50px;
	}

	&__item {
		& + #{&} {
			margin-top: 32px;
		}
		.title {
			@include BeauSansSemi;
			@include font($text-color, 24px, 1);
			letter-spacing: -1px;
			&.is-link:hover {
				color: $brand-color;
			}
		}
	}

	&__persons-title {
		@include BeauSansBlack;
		@include font($title-color, 24px, 44px);
		margin: 0 0 16px 0;
		@media (max-width: map-get($breakpoints, small)) {
			white-space: nowrap;
		}
	}

	&__person {
		display: inline-block;
		font-size: 0;
		vertical-align: top;
		margin-right: 88px;
		margin-bottom: 24px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-right: 24px;
		}
	}

	&__person-photo {
		width: 80px;
		height: 80px;
		border-radius: 40px;
		display: inline-block;
		vertical-align: middle;
		overflow: hidden;
		background-repeat: no-repeat;
		background-size: 80px 80px;
		background-position: center;
	}

	&__person-name {
		@include BeauSansLight;
		font-size: 16px;
		line-height: 1.2;
		vertical-align: middle;
		display: inline-block;
		margin-left: 16px;
	}

	&__map {
		margin-top: 72px;
		margin-bottom: 65px;
	}

	&__social {
		margin-bottom: 88px;
	}
}