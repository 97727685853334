.page {

	&__hero {
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: right bottom;
		margin-top: -96px;
		padding-top: 150px;
		height: 465px;
		@media (max-width: map-get($breakpoints, medium)) {
			padding-top: 40px;
			margin-top: 64px;
			height: 340px;
			.b-want-help {
				margin-top: 80px;
			}
		}

		&--page-main {
			height: 570px;
			@media (max-width: map-get($breakpoints, medium)) {
				height: 433px !important;
			}
		}

		&--page-hki {
			height: 340px;
		}

		&--page-kid {
			height: 340px;
		}

		&--page-404 {
			padding-top: 206px;
			height: calc(100vh - 80px);
			background-position: center bottom;
		}

		&--page-contacts {
			height: 338px;
		}

		&--page-clinic {
			background-position: center bottom;
		}

		&--page-gallery {
			height: 340px;
		}
	}

	&__title {
		@include BeauSansBlack;
		@include font($title-color, 40px, 44px);
		margin: 0;
		@at-root {
			h3#{&} {
				font-size: 32px;
				line-height: 40px;
				margin: 0 0 24px 0; 
			}
		}
		@media (max-width: map-get($breakpoints, medium)) {
			font-size: 32px;
			line-height: 44px;
			letter-spacing: -0.8px;
		}
		
	}

	&__head-title {
		@include BeauSansBlack;
		@include font($title-color, 56px, 48px);
		margin: 0;
		@media (max-width: map-get($breakpoints, medium)) {
			font-size: 32px;
			line-height: 36px;
		}
	}
}