.page-happy-kids {

	&__main {
		margin-top:72px;
		margin-bottom: 88px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 40px;
			margin-bottom: 80px;
		}
	}
	
	&__main-grid {
		@include is-grid(2, 24);
		.b-person-info {
			height: 100%;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			@include is-grid(1, 24);
		}
	}	

	&__footer {
		margin-top: 90px;
	}
}

// mods 

.page-happy-kids {

	.page-happy-kids__main-grid .page-social-group {
		margin: 55px 0 80px;
	}

	.page__hero .page__head-title {
		color: $brand-color;
	}
}