.b-want-help {
	background-color: rgba(#ffffff, 0.56);
	height: 56px;
	border-radius: 28px;
	padding: 4px;
	display: flex;
	align-items: center;
	
	&__label {
		margin-left: 28px;
		white-space: nowrap;
		@include BeauSansSemi;
		@include font($brand-color, 16px, 1);
	}

	&__button {
		margin-left: 40px;
	}
}

.b-want-help--reverse {
	.b-want-help__label {
		margin-left: 0;
	}
	.b-want-help__button {
		margin-right: 17px;
		margin-left: 0;
		.button__text {
			padding: 0 24px;
		}
	}
}

.b-want-help--fit {
	max-width: 304px;
}