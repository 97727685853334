.subscribe {
	
	&__container {
		margin: 0 auto;
		width: 600px;
		height: 545px;
		background-image: url('/img/heart_lg.png');
		background-repeat: no-repeat;
		background-position: center top;
		padding-top: 120px;
		@media (max-width: map-get($breakpoints, medium)) {
			width: 320px;
			background-size: 100% auto;
			height: 290px;
			padding-top: 56px;
		}
	}

	@media (max-width: map-get($breakpoints, medium)) {
		> .wrapper {
			padding: 0;
		}
	}

	&__title {
		@include BeauSansBlack;
		@include font(#fff, 24px, 1);
		letter-spacing: -0.5px;
		margin: 0 0 10px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin: 0 0 3px;
		}
	}

	&__text {
		color:#fff;
		max-width: 400px;
		margin: 0 auto;
		@media (max-width: map-get($breakpoints, medium)) {
			font-size: 12px;
			line-height: 14px;
		}
	}

	&__form {
		max-width: 344px;
		height: 56px;
		margin: 32px auto 0; 
		position: relative;
		.is-form__field .ff-error-parent {
			color:$text-color;
			padding-left: 16px;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			margin: 18px auto 0;
			transform: scale(0.6);
			transform-origin: 50% 0;
		}
	}

	&__input {
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.22);
		border-radius: 28px;
	}

	&__submit {
		width: 48px;
		height: 48px;
		border-radius: 24px;
		overflow: hidden;

		&.button {
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			right: 4px;
			background-image: url('/img/mail.svg'), linear-gradient(180deg, #ffc039 0%, #cb6d00 100%);
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}