.page-payments {
	
	&__main {
		margin-top: -62px;
		.is-table {
			margin-bottom: 0;
		}
		.show-more-table {
			@include BeauSansSemi;
			@include font($brand-color, 16px, 20px);
			display: block;
			text-align: center;
			padding: 38px 16px 34px;
			background-color: #f6f7f9;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			.is-table {
				margin-left: -12px;
				margin-right: -12px;

				table thead td {
					font-size: 16px;
					line-height: 18px;
				}

				table td {
					padding: 12px 0 12px 12px;
				}

			 	table td:nth-of-type(3) {
			 		display: none;
			 	}
			}
		}
	}

	&__filter {
		display: flex;
		flex-flow: row wrap;
		margin-bottom: 56px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-bottom: 0;
		}
	}

	&__filter-item {
		display: flex;
		align-items: center;
		margin: 0 64px 24px 0; 
		.is-select {
			width: 288px;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__fitler-lebel {
		@include BeauSansBlack;
		color: $text-color;
		font-size: 18px;
		letter-spacing: -0.37px;
		display: inline-block;
		margin-right: 16px;
	}

	&__chart-sect {
		margin-top: 80px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 56px;
		}
	}

	&__footer {
		margin-top: 90px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 80px;
		}
	}
}