.is-table {
  margin-bottom: 60px;
  table {
    width: 100%;
    border-collapse: collapse;
    td {
      cursor: default;
      border-collapse: collapse;
      padding: 20px 0 24px 24px;
    }
    thead {
      td {
        vertical-align: bottom;
        @include BeauSansBlack;
        @include font($text-color, 18px, 24px);
      }
    }
    tbody {
      td {
      	@include BeauSansLight;
        vertical-align: top;
        transition: background .2s;
        @include font($text-color, 14px, 24px);
      }
      tr:nth-child(odd) {
        background:#f9f9f9;
      }
    }
    a {
      text-decoration: underline;
      transition: color .2s;
      &:hover {
        color: #c52f33;
      }
    }
  }
}

.is-responsive-table {
  @media (max-width: 768px) {
    td {
      display: block;
      position: relative;
      text-align: left !important;
      &[data-a-title] {
        &:before {
          content: attr(data-a-title);
          @include BeauSansLight;
          @include font($text-color, 14px, 24px);
          display: block;
          text-align: left;
          position: relative;
          padding-right: 5px;
          margin-top: 20px;
      }
      }
    } 
  }
}