.page-news {
	
	&__main {
		margin-top: -160px;
		margin-bottom: 84px;
		@include is-grid(3, 32);
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: -40px;
			margin-bottom: 80px;
			@include is-grid(1, 24);
		}

		// .is-grid__item {
		// 	[id^=vk_post] , iframe {
		// 		height: 100% !important;
		// 	}
		// }
	}

	&__chart-sect {
		margin-bottom: 80px;
	}

	&__pager-block {
		margin-top: 16px;
		background-color: #f6f7f9;
		padding: 30px 10px;
	}
}
