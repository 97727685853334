.m-item {
	box-shadow: 0 2px 6px rgba(224, 228, 235, 0.56);
	border: 1px solid #e0e4eb;
	background-color: #fff;
	padding: 12px;
	height: 229px;
	text-align: center;
	font-size: 16px;
	@include BeauSansLight;
	.responsive-img {
		margin: 36px auto 19px;
	}

	p {
		margin: 0;
	}
}