.cl-item {
	display: block;
	background-color: #f6f7f9;
	padding: 16px 56px 16px 16px;
	@media (max-width: map-get($breakpoints, medium)) {
		padding: 12px 12px 32px;
	}

	&__inner {
		display: flex;
		align-items: flex-start;
		@media (max-width: 580px) {
			flex-flow: column nowrap;
			align-items: center;
		}
	}

	&__img {
		width: 376px;
		height: 240px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
		@media (max-width: map-get($breakpoints, medium)) {
			width: 180px;
		}
		@media (max-width: 580px) {
			width: 100%;
			max-width: 480px;
		}
	}

	&__desc {
		margin-left: 40px;
		flex:1;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-left: 16px;
		}
		@media (max-width: 580px) {
			margin-left: 0;
			width: 100%;
			max-width: 480px;
			margin-top: 16px;
		}
	}

	&__title {
		font-size: 24px;
		font-weight: 400;
		line-height: 44px;
		margin: 8px 0 20px;
	}

	&__details {
		border-top: 1px solid #e0e4eb;
		padding-top: 16px;

		.icon-item + .icon-item {
			margin-top: 8px;
		}
	}

	&__footer {
		margin-top: 48px;
		padding-left: 24px;
    .col + .col {
    	margin-left: 74px;
    }
    .icon-item + .icon-item {
    	margin-top: 8px;
    }
    @media (max-width: map-get($breakpoints, medium)) {
    	padding-left: 0;
    	margin-top: 24px;
    	.col + .col {
	    	margin-left: 24px;
	    }
    	.icon-item__text {
    		font-size: 14px;
    		line-height: 20px;
    	}
    	.icon-item__icon {
    		margin-top: 3px;
    	}
    }
    @media (max-width: 580px) {
    	.is-columns {
    		flex-flow: column nowrap;
    		align-items: center;
    		.col + .col {
		    	margin-left: 0;
		    	margin-top: 24px;
		    }
		    .col {
		    	max-width: 480px;
		    }
    	}
    }
	}

	&__col-ttl {
		@include BeauSansBlack;
		font-size: 18px;
		letter-spacing: -0.38px;
		margin-bottom: 11px;
		@media (max-width: map-get($breakpoints, medium)) {
			font-size: 16px;
		}
	}
}