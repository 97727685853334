.gallery {
	
	&__wrapper {
		display: flex;
	}

	&__main {
		flex-grow: 1;
		flex-basis: 0;
		max-width: 800px;
		background-color: #fff;
		@media (max-width: map-get($breakpoints, medium)) {
			max-width: none;
			width: 100%;
		}
	}

	&__item {
		width: 100% !important;
		height: 0;
		padding-bottom: 64.5%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&__top {
		position: relative;
	}

	&__caption {
		padding-left: 24px;
		position: absolute;
		z-index: 2;
		bottom: 0;
		left: 100%;
		width: 100%;
		max-width: 288px;
		.name {
			font-size: 24px;
			line-height: 32px;
		}
		.age {
			margin-top: 8px;
			color: #4d575c;
			font-size: 16px;
			line-height: 1;
			@include BeauSansLight;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			position: relative;
			left: 0;
			margin-top: 10px;
			max-width: none;
		}
		@media (max-width: map-get($breakpoints, small)) {
			padding-left: 12px;
			.name {
				font-size: 20px;
			}
		}
	}

	&__pager {
		height: 100%;
		line-height: 56px;
		text-align: center;
		white-space: nowrap;
		@include BeauSansSemi;
		font-size: 16px;
	}

	&__thumbs {
		height: 68px;
		margin-top: 52px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 24px;
		}
		@media (max-width: 640px) {
			height: auto;
		}
	}

	&__thumb {
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		@media (max-width: 640px) {
			height: 0;
			padding-bottom: calc((100% - 36px)/ 4);
		}
		@media (max-width: map-get($breakpoints, small)) {
			padding-bottom: calc((100% - 20px) / 3);
		}
	}

	&__nav {
		position: absolute;
		z-index: 2;
		top:100%;
		left: 50%;
		padding: 0 56px;
		transform: translateX(-50%);
		width: 232px;
		height: 56px;
		border-radius: 28px;
		border: 1px solid #e0e4eb;
		background-color: #ffffff;
		margin-top: -28px;

		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 12px;
			position: relative;
		}

		&-arr {
			width: 32px;
			height: 32px;
			border-radius: 16px;
			background-image: linear-gradient(180deg, #f52239 0%, #af1727 100%);
			border-radius: 16px;
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			&::before {
				content: '';
				display: block;
				position: absolute;
				top:0;
				left:0;
				right: 0;
				bottom: 0;
				background-image: url('/img/arr.png');
				background-repeat: no-repeat;
				background-size: 5px 10px;
			}

			&--next {
				right: 12px;
				&::before {
					transform: scale(-1);
					background-position: left 12px center;
				}
			}

			&--prev {
				left: 12px;
				&::before {
					background-position: left 12px center;
				}
			}
		}
	}
}