.b-person-info {
	display: block;
	position: relative;
	padding: 16px;
	background-color: #f6f7f9;
	border-radius: 2px;
	display: flex;
	align-items: flex-start;
	& + & {
		margin-top: 24px;
	}

	@media (max-width: map-get($breakpoints, medium)) {
		flex-flow: column nowrap;
		margin-left: -12px;
		margin-right: -12px;
		padding: 12px;
		& + & {
			margin-top: 32px;
		}
	}

	&__left-col {
		width: 272px;
		height: 314px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
		position: relative;

		span.alt {
			display: inline-block;
			@include centerIt(absolute);
		}

		@media (max-width: map-get($breakpoints, medium)) {
			width: 100%;
			height: 272px;
		}
	} 

	&__main {
		padding: 16px 22px 16px 48px;
		flex-grow: 1;
		@media (max-width: map-get($breakpoints, medium)) {
			padding: 24px 0 23px;
			width: 100%;
		}
	}

	&__name {
		@include BeauSansReg;
		@include font($text-color, 32px, 44px);
		margin: 0 0 6px;
		&.is-link {
			&:hover {
				color: $brand-color;
			}
		}
	}

	&__prof {
		flex-grow: 1;
		padding-right: 24px;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@media (max-width: map-get($breakpoints, medium)) {
			flex-flow: row wrap;
			.button {
		    display: block;
		    margin: 0 auto;
		    flex-basis: 0;
		    width: 100%;
			}
		}
		@media (max-width: map-get($breakpoints, small)) {
			.button {
				flex-basis: auto;
			}
		}
	}

	&__desc {
		
	}

	&__num-lbl {
		text-align: center;
		min-width: 104px;
		border-radius: 4px;
		background-color: #ffffff;
		padding: 11px 9px 8px;
		margin-top: 12px;
		.ttl {
			@include BeauSansLight;
			@include font($mid-grey, 12px, 18px);
			letter-spacing: 1.5px;
			text-transform: uppercase;
			display: block;
			margin-bottom: 3px;
		}
		.num {
			@include BeauSansReg;
			@include font($text-color, 18px, 24px);
			display: block;
		}

		@media (max-width: map-get($breakpoints, medium)) {
			position: absolute;
			top: 20px;
			right: 20px;
			margin-top: 0;
		}
	}

	&__progress {
		margin: 36px 0;
		@media (max-width: map-get($breakpoints, medium)) {
			margin: 24px 0 48px;
		}
	}

	&__social {
		margin-top: -5px;
		@media (max-width: map-get($breakpoints, medium)) {
			width: 100%;
			text-align: center;
			margin-top: 32px;
		}
	}
}

// mods 

.b-person-info--compact {
	.b-person-info__left-col {
		width: 256px;
		height: 280px;
		@media (max-width: map-get($breakpoints, medium))  {
			width: 100%;
		}
	}
	.b-person-info__main {
		padding: 19px 28px 0 24px;
		flex:1;
		@media (max-width: map-get($breakpoints, medium))  {
			padding: 24px 0 32px;
		}
	}
	.b-person-info__name {
		font-size: 24px;
		line-height: 28px;
	}
	.b-person-info__prof {
		padding-right: 0;
	}
	.b-person-info__desc {
		font-size: 12px;
	}
	.progress-bar__label {
		font-size: 12px;
		text-transform: none;
		line-height: 1;
	}
	.b-person-info__progress {
		margin: 26px 0 30px;
	}
}