.is-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  .col {
    flex-grow: 1;
    width: 100%;
  }
  .col + .col {
    margin-left: 0;
  }
  &.gap-10 {
    .col + .col { margin-left: 10px; }
  }
  &.gap-26 {
    .col + .col { margin-left: 26px; }
  }
  &.gap-40 {
    .col + .col { margin-left: 40px; }
  }
  &.gap-60 {
    .col + .col { margin-left: 60px; }
  }
  &.gap-80 {
    .col + .col { margin-left: 80px; }
  }
  &.gap-120 {
    .col + .col { margin-left: 120px; }
  }
  .col-demo {
    height: 50px;
    margin: 0 0 40px 0;
    background: $brand-color;
  }
}