.page-social-group {
	
	&__title {
		@include BeauSansBlack;
		margin: 0 0 24px;
		font-size: 24px;
		letter-spacing: -0.5px;
	}

	&__group {
		min-height: 1px;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: map-get($breakpoints, small)) {
			flex-wrap: wrap;
		}
	}

	&__btn {
		width: 80px;
		height: 55px;
		border-radius: 4px;
		border: 1px solid rgba(59, 89, 152, 0.15);
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		transition: border-color 0.3s ease;
		&::before {
			content: '';
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;
			display: inline-block;
			width: 65px;
			height: 40px;
			box-shadow: 0 4px 20px #3b5998;
			border-radius: 2px;
			background-color: #3b5998;
			opacity: 0;
			transition: opacity 0.3s ease;
			top: 10px;
		}
		& + #{&} {
			margin-left: 24px;
		}
		@media (max-width: map-get($breakpoints, small)) {
			width: calc(100% / 2 - 48px);
			margin: 12px;
			& + #{&} {
				margin-left: 12px;
			}
		}
		
		&:hover {
			&.odn {
				border-color: rgba(#f8941e, 0.15);
			}
			&.tw {
				border-color: rgba(#55acee, 0.15);
			}
			&.vk {
				border-color: rgba(#4d76a1, 0.15);
			}
			&.fb {
				border-color: rgba(#3b5998, 0.15);
			}
			&::before {
				opacity: 0.3;
			}
		}
	}

	&__icon {
		display: inline-block;
		margin: 0 auto;
		use {
			&.icon--fb {
				fill: #3b5998;
			}
			&.icon--vk {
				fill: #4d76a1;
			}
			&.icon--tw {
				fill: #55acee;
			}
			&.icon--odn {
				fill: #f8941e;
			}
		}
	}

	// .page-social-group__icon use.icon {
	// 	fill: #b7c8d0;
	// 	transition: fill 0.3s ease-in-out;
	// }
}