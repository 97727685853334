// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.is-header {
	position: relative;
	z-index: 100;
	width: 100%;
	margin: 0 auto;

	&__top {
		background-color: #fff;
		height: 48px;
	}

	&__top-inner {
		height: 100%;
		display: flex;
		flex-flow: row nowrap;
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 54px 0 40px;
		@media (max-width: 1300px) {
			padding: 0 10px;
		}
	}

	&__nav {
		flex-grow:1;
		flex-basis: auto;
	}

	&__right {
  	position: relative;
  	margin-left: auto;
  	.mobile-menu-btn {
  		padding: 4px 3px;
  		margin-right: 7px;
  		-webkit-tap-highlight-color: rgba($title-color, 0.15);
  		svg {
  			display: block;
  		}
  		use {
				fill: $brand-color;
			}
  	}
	}

	&__phone-group {
		display: flex;
		align-items: flex-start;
		height: 100%;
		.label {
			@include font($light-grey, 14px, 18px);
			padding: 17px 0 15px;
		}
		.phone {
			@include BeauSansBlack;
			@include font($text-color, 14px, 18px);
			letter-spacing: -1px;
			white-space: nowrap;
			margin-left: 24px;
			padding: 17px 0 15px;
			&:hover {
				color: $brand-color;
			}
		}
	}

	&__main {
		display: block;
		position: relative;
		width: 100%;
		padding: 20px 0;
		.is-header__right {
			display: flex;
			align-items: center;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			height: 64px;
			background-color: #fff;
			position: fixed;
			top:0;
			left:0;
			right: 0;
			padding: 16px 0;
		}
	}

	&__main-inner {
		display: flex;
		flex-flow: row nowrap;
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 36px 0 40px;
		@media (max-width: 1300px) {
			padding: 0 10px;
		}
	}

	&__logo {
		display: flex;
		flex-grow: 0;
		flex-basis: auto;
		align-items: flex-start;
		padding-top: 7px;

		use {
			fill: $brand-color;
		}
		@media (max-width: map-get($breakpoints, medium)) {
			padding-top: 0;
			svg {
				width: 38px;
				height: 32px;
			}
		}
	}

	&__logo-title {
		display: inline-block;
		@include BeauSansSemi;
		@include font($text-color, 20px, 26px);
		letter-spacing: -0.4px;
		white-space: nowrap;
		margin: 10px 0 0 10px;
		@media (max-width: map-get($breakpoints, medium)) {
			font-size: 16px;
			line-height: 1;
			margin: 7px 0 0 7px;
		}
	}

	&__want-help {
		margin-left: 32px;
		@media (max-width: 1200px) {
			margin-left: 10px;
			.b-want-help__button, .b-want-help__label {
				margin-left: 18px;
			}
		}
	}
}