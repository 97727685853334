.aside-banner {
	padding: 48px 24px;
	background-color: #fff;
	text-align: center;
	min-height: 410px;

	&__icon {
		margin: 0 auto;
	}

	&__title {
		@include BeauSansBlack;
		@include font($brand-color, 24px, 32px);
		margin: 16px 0 20px;
	}

	&__text {
		line-height: 28px;
		b {
			@include BeauSansSemi;
		}
	}

	&__link {
		@include BeauSansSemi;
		@include font($light-grey, 14px, 18px);
		text-transform: uppercase;
		display: inline-block;
		margin-top: 48px;
		@include on-event {
			color: $brand-color;
		}
	}
}