.icon-item {
	display: flex;
	align-items: flex-start;
	font-size: 0;
	padding-bottom: 8px;

	&__icon {
		display: inline-block;
		vertical-align: top;
		margin-right: 10px;
		margin-top: 6px;
		max-width: 100%;
	}

	&__text {
		display: inline-block;
		flex:1;
		vertical-align: top;
		margin: 0; 
		@include BeauSansLight;
		@include font($text-color, 16px, 32px);
	}
}