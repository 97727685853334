.b-news	{
	padding: 40px 40px 32px;

	&__global-title {
		margin-bottom: 62px;
	}

	&__title {
		@include BeauSansBlack;
		font-size: 24px;
		line-height: 30px;
		letter-spacing: -0.5px;
		margin: 0 0 10px;

	}

	&__publish-date {
		@include BeauSansSemi;
		@include font($light-grey, 14px, 18px);
		letter-spacing: -0.5px;
		margin-bottom: 20px;
	}

	&__text {
		p {

		}
	}

	&__link {
		margin-top: 50px;
	}
	@media (max-width: map-get($breakpoints, medium)) {
		padding: 24px 12px;
		img {
			width: 100% !important;
		}
	}
}