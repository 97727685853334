.b-kid-detail {
	display: block;
	background-color: rgba(#f6f7f9, 0);
	padding: 0;
	@media (max-width: map-get($breakpoints, medium)) {
		margin-left: 0;
		margin-right: 0;
	}
	
	&__header {
		position: relative;
		padding-right: 120px;
		@media (max-width: map-get($breakpoints, medium)) {
			padding-right: 0;
		}
	}

	&__main {
		display: flex;
		margin-top: 50px;
		margin-bottom: 64px;
		@media (max-width: map-get($breakpoints, medium)) {
			flex-flow: column nowrap;
		}
	}

	&__img-col {
		width: 568px;
		height: 480px;
		background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    @media (max-width: map-get($breakpoints, medium)) {
    	margin: 0 auto;
    	position: relative;
    }
    @media (max-width: map-get($breakpoints, small)) {
			width: 100%;
			max-width: 420px;
			height: 272px;
    }
	}

	&__details-col {
		margin-left: 56px;
		padding-top: 86px;
		flex: 1;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-left: 0;
			padding-top: 32px;
		}
	}

	&__title {
		margin: 0 0 22px 0;
	}

	&__program-lbl {
		position: absolute;
		top: 0;
		right: 0;
		@media (max-width: map-get($breakpoints, medium)) {
			top: 8px;
			right: 8px;
		}
	}

	&__sub-ttl-lbl {
		@include BeauSansLight;
		@include font($light-grey, 16px, 24px);
		margin-bottom: 8px;
	}

	&__sub-ttl {
		@include BeauSansBlack;
		@include font($text-color, 24px, 32px);
		margin-bottom: 24px;
	}

	&__success {
		margin-top: 72px;
		position: relative;
	}

	&__success-inner {
		background-color: #f6f7f9;
		padding: 28px 32px 36px 32px;
	}

	&__success-ttl {
		@include BeauSansBlack;
		@include font($brand-color, 24px, 32px);
		position: relative;
		z-index: 2;
	}

	&__success-img {
		position: absolute;
		z-index: 1;
		right: -23px;
		top: -48px;
    bottom: -37px;
	}

	&__actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 52px;
		@media (max-width: map-get($breakpoints, small)) {
			flex-flow: column nowrap;
			justify-content: flex-start;
			margin-top: 40px;
			.button {
				margin-bottom: 40px;
				width: 100%;
			}
		}

		.is-link {
			color: #4d575c;
			&:hover {
				color: $brand-color;
			}
		}
	}
}


