.page-gallery {
	
	&__main {
		margin-top: -60px;
		margin-bottom: 88px;
		@media (max-width: map-get($breakpoints, small)) {
			 > .wrapper {
				padding: 0;
			}
		}
	}

	&__social-sect {

	}

	&__footer {
		margin-top: 88px;
	}
}