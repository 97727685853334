// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */
 }

 .border-box {
  border-radius: 2px;
  border: 1px solid #e0e4eb;
 }

/**
 * Hide text while making it readable for screen readers
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.t-center {
  text-align: center;
}

.responsive-img {
  display: block;
  width:auto;
  max-width: 100%;
  max-height: 100%;
}

.delimiter {
  display: block;
  height: 1px;
  background-color: $light-grey;
  margin: 40px 0;
  @media (max-width: 768px) {
      margin: 20px 0;  
  }
}

.quotes {
  position: relative;
  &::before {
    content: '”';
    position: absolute;
    top:0;
    left: -35px;
  }
  @media (max-width: map-get($breakpoints, medium)) {
    padding-left: 20px;
    &::before {
      left: 0;
    }
  }
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

.sfx-hidden {opacity: 0;}

.underline {
  text-decoration: underline;
}

.hidden-1199-down {
  @media (max-width: 1199px) {
    display: none!important;
  }
}

.hidden-992-up {
  @media (min-width: 992px) {
    display: none!important;
  }
}

.hidden-991-down {
  @media (max-width: 991px) {
    display: none!important;
  }
}

.hidden-767-down {
  @media (max-width: 768px) {
    display: none!important;
  }
}

.hidden-768-down {
  @media (max-width: 768px) {
    display: none!important;
  }
}

.hidden-768-up {
  @media (min-width: 768px) {
    display: none!important;
  }
}