.page-help {
	
	&__form-sect {
		margin: 36px 0 72px;
		@media (max-width: map-get($breakpoints, small)) {
			> .wrapper {
				padding: 0;
			}
		}
	}

	&__help-info {
		margin-bottom: 80px;
	}

	&__docs-sect {
		margin: 88px 0 64px;

		.b-container {
			display: flex;
			margin-top: 38px;
			.col {
				position: relative;
				padding: 48px 40px 134px;	
				background-color: #fff;
				@include BeauSansLight;
				font-size: 18px;
				p {
					margin: 0;
				}
				b {
					@include BeauSansBlack;
				}
				small {
					line-height: 18px;
					font-size: 14px;
					color: #4d575c;
				}
				table {
					width: 100%;
					border-collapse: collapse;
				}
				td {
					vertical-align: top;
					font-size: 16px;
				}
				.button-wrap {
					margin-top: 32px;
					position: absolute;
					bottom: 48px;
					left: 40px;
				}
			}

			.col1 {
				width: 600px;
			}
			.col2 {
				width: 392px;
				margin-left: auto;
			}
			@media (max-width: map-get($breakpoints, medium)) {
				flex-flow: column nowrap;
				.col1, .col2 {
					width: 100%;
					margin-left: 0;
				}
				.col {
					padding: 32px 24px 40px;
					.button-wrap {
						position: static;
						text-align: center;
					}
				}
				.col2 {
					margin-top: 30px;
					.responsive-img {
						width: 100%;
					}
				}
			}
		}
	}
}