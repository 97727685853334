.b-social-group {
	display: inline-block;

	&__label {
		@include BeauSansLight;
		@include font($mid-grey, 12px, 18px);
		display: inline-block;
		margin-bottom: 10px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-bottom: 16px;
		}
	}

	&__group {
		display: flex;
		align-items: center;
	}

	&__btn {
		& + #{&} {
			margin-left: 28px;
			@media (max-width: map-get($breakpoints, medium)) {
				margin-left: 48px;
			}
		}
		
		&:hover {
			.b-social-group__icon use.icon {
				fill: $brand-color;
			}
		}
	}

	.b-social-group__icon use.icon {
		fill: #b7c8d0;
		transition: fill 0.3s ease-in-out;
	}
}