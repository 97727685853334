.page-index {
	
	&__hero-intro {
		max-width: 540px;
		.b-want-help {
			margin-top: 32px;
		}
	}

	&__intro-text {
		margin-top: 42px;
		max-width: 380px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 16px;
		}
	}

	&__head-title {
		@include BeauSansBlack;
		@include font($brand-color, 48px, 1);
		letter-spacing: -1px;
		margin: 0;
		@media (max-width: map-get($breakpoints, medium)) {
			font-size: 32px;
		}
	}

	&__help-sect {
		margin-top: 47px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 4px;
		}
		.page__title {
			margin-bottom: 32px;
			@media (max-width: map-get($breakpoints, medium)) {
				margin-bottom: 12px;
			}
		}
		.aside-banner {
			margin-top: 5px;
			@media (max-width: map-get($breakpoints, medium)) {
				margin-top: 0;
			}
		}
	}

	&__news-sect {
		margin: 80px 0;
		@media (max-width: map-get($breakpoints, medium)) {
			margin: 48px 0 65px;
		}
		.row {
			@include clearfix;
			.col-2 {
				float: left;
				width: 40%;
			}
			.col-1 {
				float: left;
				margin-left: 40px;
				width: calc((60% - 80px) / 2);
			}
		}
		@media (max-width: map-get($breakpoints, medium)) {
			.row {
				.col-2, .col-1 {
					width: 100%;
					margin-left: 0;
				}
				.col-1 {
					margin-top: 32px;
				}
			}
		}
	}

	&__chart-sect {
		
	}

	&__last-paying-sect {
		margin: 80px 0 100px;
		.inner {
			padding: 0 83px 0 104px;
			@media (max-width: map-get($breakpoints, medium)) {
				padding: 0;
			}
		}
		.paying-ttl {
			@include BeauSansBlack;
			@include font($title-color, 24px, 32px);
		}
		.item + .item {
			@media (max-width: map-get($breakpoints, medium)) {
				padding-left: 10px;
			}
			&::before {
				content: '';
				position: absolute;
				top: 5px;
				left: 0;
				display: block;
				width: 1px;
				height: 80px;
				background-color: #e0e4eb;
			}
		}
		.item {
			position: relative;
			padding-left: 48px;
			@media (max-width: map-get($breakpoints, medium)) {
				padding-left: 0;
				padding-right: 10px;
				flex: 0 0 auto;
				width: auto;
			}
			.title {
				@include BeauSansBlack;
				@include font($text-color, 24px, 32px);
			}
			.is-text {

			}
		}
		@media (max-width: map-get($breakpoints, medium)) {
			margin: 50px 0 70px;
			.is-columns {
				padding-top: 48px;
				position: relative;
				padding-bottom: 12px;
				overflow-x: auto;
				-webkit-overflow-scrolling: touch;
				.col:not(.item) {
					position: absolute;
					top: 0;
					left: 0;
					br {
						display: none;
					}
				}
			}
		}
	}
}

.chart-section {
	.inner {
		display: flex;
		padding: 0 83px 0 104px;
		@media (max-width: map-get($breakpoints, medium)) {
			flex-flow: row wrap;
			padding: 0;

			.left-side {
				max-width: none;
				text-align: center;
				margin-bottom: 70px;
				.page__title {
					margin: 0 0 16px;
				}
				.btn-wrap {
					margin-top: 24px;
				}
			}
		}
		@media (max-width: map-get($breakpoints, small)) {
			.btn-wrap {
					.button {
						width: 100%;
					}
				}
		}
	}

	.left-side {
		max-width: 288px;
		.page__title {
			margin: 0 0 22px;
		}
		.is-text {
			line-height: 28px;
		}
		.btn-wrap {
			margin-top: 30px;
		}
	}

	.chart-container {
		margin-left: auto;
		position: relative;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		.chart-area-wrapper {
			width: 640px;
			> canvas {
				width: 640px !important;
				height: 300px !important;
			}
		}
		@media (max-width: map-get($breakpoints, medium)) {
			margin-right: -12px;
			.chart-area-wrapper {
				padding-bottom: 20px;
			}
		}
	}

}