.header-nav {
	position: relative;

	&__list {
		display: flex;
		flex-flow: row nowrap;
		align-items: stretch;
		margin:0;
		padding: 0;
	}

	&__item {
		display: inline-block;
    vertical-align: middle;
    position: relative;
    &::before {
    	content: '';
    	position: absolute;
    	width: 100%;
    	left:0;
    	right:0;
    	bottom: 0;
    	height: 1px;
    	background-color: rgba($brand-color, 0);
    }
    &:hover {
    	.header-nav__text {
    		color: $brand-color;
    	}
    }
    & + #{&} {
    	margin-left: 16px;
    }
    &.is-active {
    	&::before {
    		background-color: rgba($brand-color, 1);
    	}
    	.header-nav__text {
    		color: $brand-color;
    	}
    }
	}

	&__text {
		display: inline-block;
		white-space: nowrap;
		position: relative;
		z-index: 1;
		@include font($text-color, 14px, 18px);
		padding: 18px 8px 12px 8px;
	}
}

// mods
.header-nav--main-menu {
	.header-nav__item {
		&::before {
			content: '';
    	position: absolute;
    	width: auto;
    	height: auto;
    	left: -16px;
    	right: -16px;
    	top: 0;
    	bottom: 0;
    	border-radius: 28px;
    	background-color: rgba(#ffffff, 0);
    	z-index: 0;
		}
		&.is-active {
	  	&::before {
	  		background-color: rgba(#ffffff, 0.56);
	  	}
	  }
	}
}
