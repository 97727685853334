.help-form {
	
	&__row {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		& + #{&} {
			margin-top: 48px;
		}

		.col {
			box-sizing: border-box;
			flex-grow: 1;
			flex-basis: 0;
			max-width: 100%;
			min-height: 1px;
			@media (max-width: map-get($breakpoints, medium)) {
				flex-basis: auto;
			}

			&--sm {
				@media (min-width: 1080px) {
					width: 352px;
					flex: 0 0 auto;
				}
			}

			&--md {
				@media (min-width: 1080px) {
					margin-left: auto;
					width: 664px;
					flex: 0 0 auto;
				}
			}

			.fileList {
				margin: 0;
			}
		}
		@media (max-width: map-get($breakpoints, medium)) {
			& + #{&} {
				margin-top: 24px;
			}
			.col + .col {
				margin-top: 24px;
			}
			.is-form-field .is-input--theme {
				max-width: none;
			}
			.is-columns {
				flex-wrap: wrap;
				.col + .col {
					margin-left: 0;
					margin-top: 0;
				}
			}
		}
	}

	&__section {
		padding: 40px 32px;
		background-color: #f6f7f9;
		height: 100%;
		.title {
			@include BeauSansBlack;
			@include font($brand-color, 24px, 32px);
			margin: 0 0 32px;
			letter-spacing: -0.8px;
		}
		@media (max-width: map-get($breakpoints, small)) {
			padding: 24px 12px;
		}
	}

	&__footer {
		margin-top: 56px;
		padding: 0 32px;
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		.button .button__text {
			height: 56px;
			line-height: 58px;
		}
		.note-text {
			margin-left: 64px;
			max-width: 600px;
			&.is-style p {
				margin: 0;
			}
		}
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 32px;
			flex-flow: column-reverse nowrap;
			align-items: center;
			.note-text {
				margin-left: 0;
			}
		}
		@media (max-width: map-get($breakpoints, small)) {
			padding: 0 12px;
		}
	}

	&__submit-btn {
		margin-top: 8px;
		@media (max-width: map-get($breakpoints, medium)) {
			margin-top: 32px;
		}
		@media (max-width: map-get($breakpoints, small)) {
			width: 100%;
		}
	}
}